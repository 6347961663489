import { message, Popconfirm, Popover, Tooltip } from 'antd';
import { Rbac, useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import MessageThread from 'components/pages/DashboardPage/Project/Sections/MessageThread';
import SharedAssigneeDropDown from 'components/sharedComponents/SharedAssigneeDropDown/SharedAssigneeDropDown';
import StatusChange from 'components/sharedComponents/StatusChange/StatusChange';
import cryptoRandomString from 'crypto-random-string';
import { ContextTypes } from 'models/enums/constants';
import { SectionTypeMessages } from 'models/enums/messages';
import { TaskListView } from 'models/enums/tasklistView';
import {
  IProjectState,
  ITaskList,
  ITaskListDetails,
  IUpdatedTasks,
} from 'models/interface';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { projectService } from 'services';
import BrowserStorageService from 'services/browserStorage.service';
import { useProjectDashboardStore } from 'stores';
import {
  debounce,
  getCurrentOrganization,
  isEmptyString,
} from 'utils/commonFunctions';
import {
  markTaskDeleteInMessageThread,
  renameTaskInMessageThread,
} from 'utils/firebaseCommonFunctions';
import TaskAssignee from './TaskAssignee';
import TaskDueDate from './TaskDueDate';
import TaskPriority from './TaskPriority';
// import debounce from 'lodash.debounce';
import classNames from 'classnames';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';
import FeedbackMessageBox from 'components/pages/PointAndComment/FeedbackBoard/FeedbackMessageBox';
import {
  IFeedbackDetailsData,
  IResponseGetFeedbackbyId,
} from 'models/interface/pnc.interface';
import feedbackService from 'services/pncService/feedback.service';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';
import CustomVideoContainer from 'components/pages/PointAndComment/FeedbackBoard/CustomVideoContainer';

const Task: React.FC<{
  eachTaskDetail: ITaskListDetails;
  sectionId: number | string;
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList>>;
  setIsTaskEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
  statusDoneId?: number;
  packId?: number;
  socket?: any;
  sectionName: string;
  taskDetails?: ITaskList;
  renderType?: 'status' | 'task' | 'dueDate' | 'assignee' | 'action';
  forPnc?: boolean;
}> = ({
  eachTaskDetail,
  sectionId,
  setTaskListDetails,
  statusDoneId,
  packId,
  // socket,
  sectionName,
  taskDetails,
  renderType,
  setIsTaskEmpty,
  forPnc,
}) => {
  const {
    projectDetails,
    taskListCmsData,
    customizeSectionUpdateCmsData: customizeCmsData,
    packList,
  } = useProjectDashboardStore((state: IProjectState) => state);
  const [taskName, setTaskName] = useState(eachTaskDetail.task_name);
  const browserStorage = BrowserStorageService.getInstance();
  const userDetails = browserStorage.getUserDetails();
  const [taskDetail, setTaskDetail] = useState(eachTaskDetail);
  const [isEditMode, setIsEditMode] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const currentOrganization =
    userDetails?.organization_info &&
    getCurrentOrganization(org_key, userDetails?.organization_info);
  const index = taskDetails?.task_list_details?.findIndex(
    (i) => i.task_id === eachTaskDetail.task_id,
  );

  const { fetchNewPermission } = useFetchNewPermission();

  const { hasPermissions } = useRbac();

  //PNC Related methods
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackData, setFeedbackData] = useState<IFeedbackDetailsData[]>([]);
  const [openFeedbackMsgBoxId, setOpenFeedbackMsgBoxId] = useState<number>(0);
  const { pncFeedbackSpecificCmsData } = usePncCmsDataStore();

  useEffect(() => {
    if (openFeedbackMsgBoxId !== 0) {
      getPncFeedbackData(openFeedbackMsgBoxId);
    }
  }, [openFeedbackMsgBoxId]);

  const getPncFeedbackData = async (feedbackDetailsId: number) => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      detailsId: feedbackDetailsId,
    };
    try {
      setIsLoading(true);
      const response: IResponseGetFeedbackbyId =
        await feedbackService.getFeedbacksById(payload);
      if (response?.messageId === 1) {
        setFeedbackData(response?.data?.feedback_data);
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_details_by_id,
        );
        console.log(response?.message);
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_details_by_id,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClosePncMsgBox = () => {
    setOpenFeedbackMsgBoxId(0);
  };
  //PNC Section End ------------------------

  const findTaskIdAndUpdateMainTaskList = (
    eachTaskDetailUpdated: ITaskListDetails,
  ) => {
    let allCurrentTask: any[] = [];
    if (
      taskDetails !== null &&
      taskDetails !== undefined &&
      taskDetails.task_list_details !== undefined &&
      taskDetails.task_list_details.length > 0
    ) {
      allCurrentTask = [...taskDetails.task_list_details];
      allCurrentTask.forEach((task, index) => {
        if (task.task_id === eachTaskDetailUpdated?.task_id) {
          allCurrentTask[index] = { ...eachTaskDetailUpdated };
        }
      });
      setTaskListDetails((prevState) => ({
        ...taskDetails,
        task_list_details: allCurrentTask,
      }));
    }
  };

  useEffect(() => {
    setTaskDetail(eachTaskDetail);
  }, [eachTaskDetail]);

  const taskNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.target?.value;
    setTaskName(value);
  };

  const removeBlankNewTask = () => {
    setTaskListDetails((prev) => {
      return {
        ...prev,
        task_list_details:
          prev?.task_list_details?.length === 1
            ? prev?.task_list_details
            : prev?.task_list_details?.filter(
                (eachTask) =>
                  typeof eachTask.task_id === 'number' ||
                  !isEmptyString(eachTask?.task_name),
              ),
      };
    });
  };

  const saveNewTask = async (taskId: string) => {
    if (projectDetails?.project_id && typeof sectionId === 'number') {
      try {
        const response = await projectService.createNewTask({
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          task_name: taskName?.trim() ?? '',
          task_status_id: 1,
          task_priority_id: 2,
        });
        if (response.data.data) {
          setTaskListDetails((prev) => ({
            ...prev,
            task_list_details: prev.task_list_details?.map((each) => {
              if (each.task_id === taskId) {
                return {
                  ...each,
                  task_id: response.data.data,
                  task_name: taskName?.trim(),
                };
              }
              return each;
            }),
          }));
          // notifyChannel(
          //   userDetails,
          //   NotificationEventType.ADD_SIMPLE_TASK_ROW,
          //   JSON.stringify({
          //     taskId: response.data.data,
          //     taskName: taskName?.trim(),
          //   })
          // );
          setIsTaskEmpty?.(false);
        }
      } catch (e: any) {
        if (e?.response?.data?.messageId === -4) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
          return;
        }
        removeBlankNewTask();
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };

  const updateExistingTask = async (
    taskData: IUpdatedTasks[],
    isDelete: boolean,
  ) => {
    if (projectDetails?.project_id && typeof sectionId === 'number') {
      try {
        const { data, message, messageId } = await projectService.updateTaskV2({
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          section_details: taskData,
        });
        if (isDelete)
          setTaskListDetails((prev) => {
            let _task_list_details = prev.task_list_details;
            if (_task_list_details && _task_list_details.length === 1) {
              const newTask = {
                task_id: cryptoRandomString({ length: 5 }),
                task_rank: 1,
                task_name: '',
                due_date: '',
                assignee_dp: '',
                assignee_id: null,
                assignee_name: '',
                assignee_email: '',
                assigner_id: null,
                task_status_id: 1,
                task_priority_id: 2,
                task_status_name: taskListCmsData?.task_status_options.find(
                  (eachOption) => eachOption.status_id === 1,
                )?.status_name,
                task_priority_name: taskListCmsData?.task_priority_options.find(
                  (eachOption) => eachOption.priority_id === 2,
                )?.priority_name,
                created_by: userDetails?.user_id,
              };
              _task_list_details?.push(newTask);
              setIsTaskEmpty?.(true);
            }
            return {
              ...prev,
              task_list_details: prev.task_list_details?.filter(
                (eachTask) => eachTask.task_id !== eachTaskDetail.task_id,
              ),
            };
          });
        else
          setTaskListDetails((prev) => ({
            ...prev,
            task_list_details: data,
          }));
      } catch (error: any) {
        if (error?.response?.data?.messageId === -4) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
          return;
        }
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };
  const addNewTaskOnEnter = (
    taskId: string | number,
    event?: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event?.key === 'Enter') {
      if (!isEmptyString(taskName)) {
        setTaskName((prev) => prev?.trim());
      }
      const task_id = cryptoRandomString({ length: 5 });
      setTaskListDetails((prev) => {
        const _task_list_details = prev?.task_list_details;
        if (_task_list_details) {
          let max = _task_list_details?.reduce(function (prev, current) {
            return prev?.task_rank > current?.task_rank ? prev : current;
          })?.task_rank;
          let newTask = {
            task_id: task_id,
            task_rank: max + 1,
            task_name: '',
            due_date: '',
            assignee_dp: '',
            assignee_id: null,
            assignee_name: '',
            assignee_email: '',
            assigner_id: null,
            task_status_id: 1,
            task_priority_id: 2,
            task_status_name: taskListCmsData?.task_status_options.find(
              (eachOption) => eachOption.status_id === 1,
            )?.status_name,
            task_priority_name: taskListCmsData?.task_priority_options.find(
              (eachOption) => eachOption.priority_id === 2,
            )?.priority_name,
            created_by: userDetails?.user_id,
            custom_data: taskDetail?.custom_data,
          };
          _task_list_details?.push(newTask);
        } else {
          event.currentTarget.blur();
        }

        const _temp_details = _task_list_details?.filter(
          (eachTask) =>
            typeof eachTask.task_id === 'number' ||
            !isEmptyString(eachTask?.task_name),
        );

        if (!isEmptyString(taskName)) {
          return { ...prev, task_list_details: _task_list_details };
        } else if (!_temp_details?.length) {
          event.currentTarget.blur();
          return {
            ...prev,
            task_list_details: _task_list_details?.slice(0, 1),
          };
        }
        return { ...prev, task_list_details: _task_list_details };
      });
    }
  };

  const taskNameOnBlurHandler = (
    taskId: string | number,
    event?: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    if (!isEmptyString(taskName)) {
      setTaskName((prev) => prev?.trim());
    }
    const oldTaskName = eachTaskDetail.task_name;
    if (typeof taskId === 'string' && isEmptyString(taskName)) {
      removeBlankNewTask();
    } else if (
      typeof taskId === 'number' &&
      isEmptyString(taskName) &&
      oldTaskName
    ) {
      setTaskName(oldTaskName);
    } else if (
      typeof taskId === 'string' &&
      !isEmptyString(taskName) &&
      event?.target.value
    ) {
      saveNewTask(taskId);
    } else if (
      typeof taskId === 'number' &&
      oldTaskName &&
      oldTaskName !== taskName
    ) {
      // notifyChannel(
      //   userDetails,
      //   NotificationEventType.UPDATE_SIMPLE_TASK_ROW,
      //   JSON.stringify({
      //     ...eachTaskDetail,
      //     task_id: taskId,
      //     task_name: taskName,
      //   })
      // );
      updateExistingTask(
        [{ ...eachTaskDetail, task_id: taskId, task_name: taskName }],
        false,
      );
    }
    setIsEditMode(false);
    if (taskName) {
      renameTaskInMessageThread(
        `${SectionTypeMessages.SIMPLE_LIST_TASK}-${
          process.env.REACT_APP_STAGE
        }-${getCurrentOrganization(org_key, userDetails?.organization_info)
          ?.organization_id!}-${projectDetails?.project_id}-${sectionId}-${
          eachTaskDetail.task_id
        }`,
        taskName,
      );
    }
  };
  const deleteTaskHandler = async () => {
    if (
      projectDetails?.project_id &&
      typeof sectionId === 'number' &&
      typeof eachTaskDetail.task_id === 'number'
    ) {
      // notifyDeleteChannel(
      //   userDetails,
      //   NotificationEventType.DELETE_SIMPLE_TASK_ROW
      // );
      updateExistingTask(
        [
          {
            ...eachTaskDetail,
            task_id: eachTaskDetail.task_id,
            is_deleted: true,
          },
        ],
        true,
      );
      markTaskDeleteInMessageThread(
        `${SectionTypeMessages.SIMPLE_LIST_TASK}-${
          process.env.REACT_APP_STAGE
        }-${getCurrentOrganization(org_key, userDetails?.organization_info)
          ?.organization_id!}-${projectDetails?.project_id}-${sectionId}-${
          eachTaskDetail.task_id
        }`,
      );
    }
  };
  const debounceDelete = useCallback(debounce(deleteTaskHandler, 300), [
    projectDetails?.project_id,
    sectionId,
    eachTaskDetail.task_id,
  ]);
  const renderTaskName = () => {
    const name = taskName?.slice(0, 255);
    if (!isEditMode && typeof taskDetail.task_id !== 'string') {
      if (taskDetail.task_status_id === 3) {
        const selectedStatus = customizeCmsData?.done_task_list.find(
          (e) => e.status_done_view_id === statusDoneId,
        );
        if (selectedStatus) {
          return (
            <div
              className={classNames('taskNameBlk', {
                cursorPointer: !projectDetails?.is_archived,
                [selectedStatus.classname]: !!selectedStatus.classname,
              })}
              onClick={() =>
                hasPermissions(
                  [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                  projectDetails?.associated_role_id,
                ) &&
                !projectDetails?.is_archived &&
                setIsEditMode(true)
              }
              onKeyDown={() => {}}
            >
              <Tooltip title={name} placement="left" color={'#2E364C'}>
                <span className="simpleTaskListTaskName">{name}</span>
              </Tooltip>
            </div>
          );
        }
      }

      return (
        <div
          onClick={() =>
            hasPermissions(
              [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
              projectDetails?.associated_role_id,
            ) &&
            !projectDetails?.is_archived &&
            setIsEditMode(true)
          }
          onKeyDown={() => {}}
          className="taskNameBlk"
        >
          <Tooltip title={name} placement="left" color={'#2E364C'}>
            <span className="simpleTaskListTaskName">{name}</span>
          </Tooltip>
        </div>
      );
    }

    return (
      <form onSubmit={(e) => e.preventDefault()} className="flexForm">
        <input
          autoFocus
          type="text"
          id={`text-input-${sectionId}-${taskDetail.task_id?.toString()}`}
          className="text-input-box"
          placeholder={taskListCmsData?.lbl_task_name_placeholder}
          value={taskName}
          aria-label="task name"
          onChange={taskNameChangeHandler}
          onBlur={(e) => taskNameOnBlurHandler(taskDetail.task_id, e)}
          onKeyDown={(e) => addNewTaskOnEnter(taskDetail.task_id, e)}
          maxLength={255}
          disabled={projectDetails?.is_archived}
        />
      </form>
    );
  };

  const findProjectRoleId = (e: any) => {
    let roleId = userDetails?.project_permissions?.find(
      (element: any) => element.project_id == e?.project_id,
    );

    return roleId?.role_id;
  };

  useEffect(() => {
    if (isEditMode) {
      inputRef.current?.focus();
    }
  }, [isEditMode]);

  const getEachTaskJsx = () => {
    if (renderType === 'status') {
      return (
        <span className="blkStatus cursorPointer">
          <StatusChange
            eachTaskDetail={{
              ...eachTaskDetail,
              project_id: projectDetails?.project_id ?? 0,
              section_type_id: 1,
              org_id: currentOrganization?.organization_id,
              org_key: currentOrganization?.org_key,
              section_id: sectionId,
            }}
            setEachTaskDetail={setTaskDetail}
            project_role_id={findProjectRoleId({
              ...eachTaskDetail,
              project_id: projectDetails?.project_id,
              section_type_id: 1,
              org_id: currentOrganization?.organization_id,
              org_key: currentOrganization?.org_key,
            })}
            index={index ?? -1}
            // socket={socket}
            findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
            taskListDetails={taskDetails}
            packId={packId}
            packList={packList}
            apiVersion="v2"
          />
        </span>
      );
    }
    if (renderType === 'task') {
      return (
        <div className="task-column">
          {typeof eachTaskDetail.task_id !== 'string' &&
            (!forPnc ? (
              <MessageThread
                sectionId={sectionId}
                taskId={taskDetail.task_id}
                sectionType={SectionTypeMessages.SIMPLE_LIST_TASK}
                threadName={taskDetail.task_name!}
                sectionName={sectionName}
                taskDetails={taskDetail}
              />
            ) : (
              taskDetail?.pnc_feedback_id &&
              (taskDetail?.pnc_feedback_asset_url &&
              !taskDetail?.pnc_feedback_asset_url.includes('.webm') ? (
                <Popover
                  content={
                    taskDetail?.pnc_feedback_details_id && (
                      <FeedbackMessageBox
                        feedbackId={taskDetail?.pnc_feedback_id}
                        feedbackDetailsId={taskDetail?.pnc_feedback_details_id}
                        handleCancel={() => setOpenFeedbackMsgBoxId(0)}
                        type={'image'}
                        showHeader={true}
                        isDraggable={true}
                        feedbackData={feedbackData}
                        setFeedbackData={setFeedbackData}
                        isLoading={isLoading}
                        autoFocus={true}
                      />
                    )
                  }
                  overlayClassName="feedbackPopover commentContainer"
                  placement="bottomRight"
                  trigger={['click']}
                  arrow={false}
                  open={
                    openFeedbackMsgBoxId === taskDetail?.pnc_feedback_details_id
                  }
                  onOpenChange={() => setOpenFeedbackMsgBoxId(0)}
                >
                  <span
                    onClick={() => {
                      taskDetail?.pnc_feedback_details_id &&
                        setOpenFeedbackMsgBoxId(
                          taskDetail?.pnc_feedback_details_id,
                        );
                    }}
                    className="cmnIcon message"
                  ></span>
                </Popover>
              ) : (
                taskDetail?.pnc_feedback_details_id &&
                taskDetail?.pnc_feedback_asset_url && (
                  <CustomVideoContainer
                    feedbackId={taskDetail?.pnc_feedback_id}
                    feedbackDetailsId={taskDetail?.pnc_feedback_details_id}
                    src={taskDetail?.pnc_feedback_asset_url}
                    onClick={() => setOpenFeedbackMsgBoxId(0)}
                  >
                    <span
                      onClick={() => {
                        taskDetail?.pnc_feedback_details_id &&
                          setOpenFeedbackMsgBoxId(
                            taskDetail?.pnc_feedback_details_id,
                          );
                      }}
                      className="cmnIcon message"
                    ></span>
                  </CustomVideoContainer>
                )
              ))
            ))}
          <TaskPriority
            eachTaskDetail={taskDetail}
            sectionId={sectionId}
            // notifyChannel={notifyChannel}
            setEachTaskDetail={setTaskDetail}
            findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
            setTaskListDetails={setTaskListDetails}
            taskDetails={taskDetails}
          />
          {renderTaskName()}
        </div>
      );
    }

    if (renderType === 'dueDate') {
      return (
        <TaskDueDate
          eachTaskDetail={taskDetail}
          sectionId={sectionId}
          // notifyChannel={notifyChannel}
          setEachTaskDetail={setTaskDetail}
          findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
          setTaskListDetails={setTaskListDetails}
          taskDetails={taskDetails}
        />
      );
    }
    if (renderType === 'assignee') {
      return (
        <SharedAssigneeDropDown
          eachTaskDetail={taskDetail}
          sectionId={sectionId}
          setEachTaskDetail={setTaskDetail}
          viewType={TaskListView.LIST}
          findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
          contextType={ContextTypes.TASKLIST}
          // currentSection={currentSection}
          // setCurrentSection={setCurrentSection}
        />
      );
      return (
        <TaskAssignee
          eachTaskDetail={taskDetail}
          sectionId={sectionId}
          // notifyChannel={notifyChannel}
          setEachTaskDetail={setTaskDetail}
          findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
          setTaskListDetails={setTaskListDetails}
          taskDetails={taskDetails}
        />
      );
    }
    if (renderType === 'action') {
      return (
        <>
          {!isEditMode ? (
            <Rbac
              allowedPermissions={[
                ERbacPermissions.PROJECT_SECTION_TASK_DELETE,
              ]}
              project_role_id={projectDetails?.associated_role_id}
            >
              <Popconfirm
                title={taskListCmsData?.lbl_task_delete_message}
                onConfirm={debounceDelete}
                okText="Delete"
                okButtonProps={{
                  style: { backgroundColor: '#ba1a1a' },
                }}
              >
                <div className="action-icons">
                  <span className="cmnIcon deleteBin" onKeyDown={() => {}} />
                </div>
              </Popconfirm>
            </Rbac>
          ) : (
            <></>
          )}
        </>
      );
    }

    return <></>;
  };

  return <>{getEachTaskJsx()}</>;
};

export default Task;
