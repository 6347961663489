import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { message, notification } from 'antd';
import {
  ICustomFieldModalAttributes,
  IGridList,
  IGridListStageDetail,
  IGridListTaskDetail,
  IProjectSections,
  IProjectState,
} from 'models/interface';
import { useProjectDashboardStore, useUserStore } from 'stores';
import TaskListWrapper from './Task/TaskListWrapper';
import StageListWrapper from './Stage/StageListWrapper';
import { loggerService, projectService } from 'services';
import { getCurrentOrganization } from 'utils/commonFunctions';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import AddStageCTA from './Stage/AddStageCTA';
import AddTaskCTA from './Task/AddTaskCTA';
import { GRID_VIEW_TYPES } from './GridList';
import Table from './Table/Table';
import CompactView from './CompactView/CompactView';

const GridListDetails: FC<{
  sectionDetails: IProjectSections;
  // sectionToggleState: 'collapsed' | 'expanded';
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  getSectionDetails: () => void;
  customfieldCmsData?: ICustomFieldModalAttributes;
}> = ({
  sectionDetails,
  // sectionToggleState,
  gridListDetails,
  setGridListDetails,
  getSectionDetails,
  customfieldCmsData,
}) => {
  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const { gridListCmsData, projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  // Functions
  const reOrderTask = async (
    sourceTaskId: number,
    destinationTaskId: number,
  ) => {
    try {
      let response = await projectService.reOrderGridTask({
        organizationId: +getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        userId: userDetails.user_id,
        projectId: projectDetails?.project_id!,
        sectionId: +sectionDetails.section_id,
        sourceTaskId,
        destinationTaskId,
      });

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: response.data.grid_list_details.task_details,
          block_details: response.data.grid_list_details.block_details,
        },
      } as IGridList);
    } catch (err) {
      message.error(gridListCmsData?.lbl_generic_error_message, 3);
      await loggerService.log({
        severity: 'High',
        message: 'task delete failed',
        payload: 'DB Error',
      });
    }
  };

  const reOrderStage = async (
    sourceStageId: number,
    destinationStageId: number,
  ) => {
    try {
      let response = await projectService.reOrderGridStage({
        organizationId: +getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        userId: userDetails.user_id,
        projectId: projectDetails?.project_id!,
        sectionId: +sectionDetails.section_id,
        sourceStageId,
        destinationStageId,
      });

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: response.data.grid_list_details.stage_details,
          block_details: response.data.grid_list_details.block_details,
        },
      } as IGridList);
    } catch (err) {
      message.error(gridListCmsData?.lbl_generic_error_message, 3);
      await loggerService.log({
        severity: 'High',
        message: 'task delete failed',
        payload: 'DB Error',
      });
    }
  };

  const onDragEndTask = (result: DropResult) => {
    if (gridListDetails) {
      let source = result.source;
      let destination = result.destination!;

      if (!source || !destination) return;

      if (source.index !== destination.index) {
        let sourceTask =
          gridListDetails.grid_list_details!.task_details![source.index];

        let destinationTask =
          gridListDetails.grid_list_details!.task_details![destination.index];

        let otherTaskList =
          gridListDetails.grid_list_details!.task_details?.filter(
            (ele: IGridListTaskDetail) => ele.task_id !== sourceTask.task_id,
          );

        otherTaskList?.splice(destination.index, 0, sourceTask);

        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            task_details: otherTaskList!,
          },
        } as IGridList);

        (async () => {
          await reOrderTask(sourceTask.task_id, destinationTask.task_id);
        })();
      }
    }
  };

  const onDragEndStage = (result: DropResult) => {
    if (gridListDetails) {
      let source = result.source;
      let destination = result.destination!;

      if (!source || !destination) {
        return;
      }

      if (source.index !== destination.index) {
        let sourceStage =
          gridListDetails.grid_list_details!.stage_details![source.index];

        let destinationStage =
          gridListDetails.grid_list_details!.stage_details![destination.index];

        let otherStageList =
          gridListDetails.grid_list_details!.stage_details?.filter(
            (ele: IGridListStageDetail) =>
              ele.stage_id !== sourceStage.stage_id,
          );

        otherStageList?.splice(destination.index, 0, sourceStage);

        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            stage_details: otherStageList!,
          },
        } as IGridList);

        (async () => {
          await reOrderStage(sourceStage.stage_id, destinationStage.stage_id);
        })();
      }
    }
  };

  const getViewClass = () => {
    const view_id = gridListDetails.view_id;
    if (gridListDetails?.compactView) return '';
    return GRID_VIEW_TYPES.find((i) => i.id === view_id)?.className ?? '';
  };

  // if (sectionToggleState !== 'expanded') return <></>;

  const taskDetails = gridListDetails.grid_list_details?.task_details || [];

  if (gridListDetails?.compactView) {
    return (
      <CompactView
        gridListDetails={gridListDetails}
        sectionDetails={sectionDetails}
        // sectionToggleState={sectionToggleState}
        setGridListDetails={setGridListDetails}
        customfieldCmsData={customfieldCmsData}
        getSectionDetails={getSectionDetails}
      />
    );
  }

  return (
    <div className="gridList2DOuterWrap">
      <div className={'gridList2DWrap ' + getViewClass()}>
        <Table
          sectionDetails={sectionDetails}
          gridListDetails={gridListDetails}
          setGridListDetails={setGridListDetails}
          getSectionDetails={getSectionDetails}
          customfieldCmsData={customfieldCmsData}
        />
      </div>
      {projectDetails?.is_archived === false && (
        <>
          {taskDetails.length > 0 && (
            <Rbac
              allowedPermissions={[
                ERbacPermissions.PROJECT_SECTION_GRIDLIST_STAGE_ADD,
              ]}
              project_role_id={projectDetails?.associated_role_id}
            >
              <AddStageCTA
                gridListDetails={gridListDetails}
                setGridListDetails={setGridListDetails}
              />
            </Rbac>
          )}
          <Rbac
            allowedPermissions={[
              ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_ADD,
            ]}
            project_role_id={projectDetails?.associated_role_id}
          >
            <AddTaskCTA
              gridListDetails={gridListDetails}
              setGridListDetails={setGridListDetails}
            />
          </Rbac>
        </>
      )}
    </div>
  );
};

export default GridListDetails;
