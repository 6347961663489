import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { colorChanger } from 'utils/commonFunctions';

const DEFAULT_COLOR = 'var(--cs-primary-a)';
const DEFAULT_HOVER_COLOR = 'var(--cs-secondary-b)';
const DEFAULT_WHITE_COLOR = '#fff';

// Helper function to reorder the list
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

interface IDraggableCustomDropdownProps {
  options: any;
  onSelect: any;
  onDragEnd: any;
  toggleButton: any;
  customJsx?: any;
  keyIdentifier: string;
  objectKey: string;
  isDragDropDisabled?: boolean;
  closeOnSelect?: boolean;
  defaultSelected?: any;
  selectedColor?: string;
}

const DraggableCustomDropdown: React.FC<IDraggableCustomDropdownProps> = ({
  options,
  onSelect,
  onDragEnd,
  toggleButton = null,
  customJsx,
  keyIdentifier,
  objectKey,
  isDragDropDisabled = false,
  closeOnSelect = true,
  defaultSelected = null,
  selectedColor = DEFAULT_COLOR,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [draggedOptionId, setDraggedOptionId] = useState('');

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOnSelect = (option: any) => {
    if (closeOnSelect) {
      toggleDropdown();
    }
    onSelect(option);
  };

  const handleBackgroundColor = (option: any, isHovered: boolean) => {
    if (findSelected(option)) {
      return (
        colorChanger(-0.5, selectedColor ?? DEFAULT_COLOR, undefined, true) ??
        selectedColor
      );
    }
    if (
      isDragging &&
      draggedOptionId?.toString() === option?.[keyIdentifier]?.toString()
    ) {
      return selectedColor ?? DEFAULT_HOVER_COLOR;
    }

    if (isHovered && !isDragging) {
      return selectedColor ?? DEFAULT_HOVER_COLOR;
    } else {
      return DEFAULT_WHITE_COLOR;
    }
  };

  const findSelected = (option: any) => {
    if (keyIdentifier) {
      return (
        defaultSelected?.[keyIdentifier].toString() ===
        option?.[keyIdentifier].toString()
      );
    }
    return false;
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedOptions = reorder(
      options,
      result.source.index,
      result.destination.index,
    );
    setIsDragging(false);
    setDraggedOptionId('');
    onDragEnd(reorderedOptions);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const defaultToggleButton = (
    <button onClick={toggleDropdown}>Dropdown</button>
  );

  const onDragStart = (result: any) => {
    setDraggedOptionId(result.draggableId);
    setIsDragging(true);
  };

  const draggableOptions = (option: any, index: any) => {
    return (
      <Draggable
        key={option?.[keyIdentifier] ?? index}
        draggableId={`${option?.[keyIdentifier] ?? index}`}
        index={index}
        isDragDisabled={isDragDropDisabled}
      >
        {(provided, snapshot) => {
          const isHovered = hoveredIndex === index;
          return (
            <li
              ref={provided.innerRef}
              {...provided.draggableProps}
              onClick={() => handleOnSelect(option)}
              className={classNames('', {
                dragging: snapshot.isDragging,
                activeTab: findSelected(option),
              })}
              style={{
                ...provided.draggableProps.style,
                backgroundColor: handleBackgroundColor(option, isHovered),
                cursor: isDragging ? 'grabbing' : 'pointer',
              }}
              onMouseEnter={() => setHoveredIndex(isDragging ? null : index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {!isDragDropDisabled && (
                <span
                  className={`dragIcon ${
                    snapshot.isDragging ? 'show' : 'hide'
                  }`}
                  {...provided.dragHandleProps}
                />
              )}
              {customJsx ? customJsx(option) : option[objectKey]}
            </li>
          );
        }}
      </Draggable>
    );
  };

  return (
    <div className="draggableCustomDropdown" ref={ref}>
      {toggleButton
        ? React.cloneElement(toggleButton, { onClick: toggleDropdown })
        : defaultToggleButton}

      {isOpen && (
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={onDragStart}>
          <Droppable
            droppableId="dropdown-options"
            isDropDisabled={isDragDropDisabled}
          >
            {(provided) => (
              <ul
                className="draggableCustomDropdown-menu"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {options.map((option: any, index: number) =>
                  draggableOptions(option, index),
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default DraggableCustomDropdown;
