import { Input, Popover, Tooltip, message } from 'antd';
import { useRbac } from 'auth/rbac/rbac';
import { NotificationEventType } from 'models/enums/notification';
import { TaskListView } from 'models/enums/tasklistView';
import { SearchOutlined } from '@ant-design/icons';
import {
  IBlockAssigneeDetails,
  IGridList,
  IGridListBlockDetail,
  IProjectMember,
  IProjectState,
  ITaskListDetails,
  IUserDetails,
} from 'models/interface';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { projectService } from 'services';
import { useProjectDashboardStore, useUserStore } from 'stores';
import {
  getCurrentOrganization,
  getUserGravatar,
  groupGridBlock,
} from 'utils/commonFunctions';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import ProfilePic from 'assets/e-images/profile-no-pic.jpg';
import { ContextTypes } from 'models/enums/constants';
import { useFetchNewPermission } from '../hooks';

const SharedAssigneeDropDown: FC<{
  blockId?: number;
  gridListDetails?: IGridList;
  setGridListDetails?: React.Dispatch<React.SetStateAction<IGridList>>;
  blockDetails?: IGridListBlockDetail;
  assigneeDetails?: IBlockAssigneeDetails;
  updateBlock?: (block: IGridListBlockDetail) => Promise<void>;
  showDropDown?: number;
  setShowDropDown?: React.Dispatch<React.SetStateAction<number>>;
  eachTaskDetail?: ITaskListDetails;
  viewType?: TaskListView;
  sectionId?: number | string;
  notifyChannel?: (
    userDetails: IUserDetails,
    eventType: string,
    eventValue?: string,
  ) => void;
  setEachTaskDetail?: React.Dispatch<React.SetStateAction<ITaskListDetails>>;
  findTaskIdAndUpdateMainTaskList?: (
    eachTaskDetailUpdated: ITaskListDetails,
  ) => void;

  contextType: ContextTypes;
  currentSection?:
    | {
        sectionId: number;
        popup: 'assignee' | 'dueDate' | 'message';
      }
    | undefined;
  setCurrentSection?: React.Dispatch<
    React.SetStateAction<
      | {
          sectionId: number;
          popup: 'assignee' | 'dueDate' | 'message';
        }
      | undefined
    >
  >;
  randomKey?: string;
}> = ({
  blockDetails,
  gridListDetails,
  setGridListDetails,
  blockId,
  assigneeDetails,
  updateBlock,
  showDropDown,
  setShowDropDown,
  eachTaskDetail,
  viewType,
  sectionId,
  notifyChannel,
  setEachTaskDetail,
  findTaskIdAndUpdateMainTaskList,
  contextType,
  currentSection,
  setCurrentSection,
  randomKey,
}) => {
  const { userDetails } = useUserStore((state: any) => state);
  const { taskListCmsData, projectMembers, projectDetails, gridListCmsData } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { hasPermissions } = useRbac();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const [searchMembers, setSearchMembers] = useState(projectMembers);
  const [searchInput, setSearchInput] = useState('');
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);

  const { fetchNewPermission } = useFetchNewPermission();

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (contextType === ContextTypes.GRIDLIST) {
          setShowDropDown && setShowDropDown(0);
        } else {
          setShowAssigneeDropdown(false);
        }
      }
    };
    window.addEventListener('keydown', handleEscKeyPress);

    return () => {
      window.removeEventListener('keydown', handleEscKeyPress);
    };
  }, []);

  useEffect(() => {
    if (projectMembers?.length || !showAssigneeDropdown) {
      setSearchMembers(
        projectMembers?.filter(
          (eachMember) => eachMember.email !== eachTaskDetail?.assignee_email,
        ) ?? null,
      );
      setSearchInput('');
    }
    if (setCurrentSection && eachTaskDetail) {
      if (showAssigneeDropdown === true) {
        setCurrentSection({
          sectionId: Number(eachTaskDetail.task_id),
          popup: 'assignee',
        });
      }
    }
  }, [projectMembers?.length, showAssigneeDropdown]);

  const setAssignee = async (member: IProjectMember | null) => {
    if (contextType === ContextTypes.GRIDLIST) {
      if (gridListDetails) {
        let blockDetails =
          gridListDetails.grid_list_details?.block_details!.flat()!;
        // let target = blockDetails.find(
        //   (ele: IGridListBlockDetail) => ele.block_id === blockId,
        // )!;

        let targetIndex = blockDetails.findIndex(
          (ele: IGridListBlockDetail) => ele.block_id === blockId,
        )!;
        let target = blockDetails[targetIndex];

        if (member !== null) {
          target.assignee_email = member.email;
          target.assignee_id = member.user_id;
          target.assignee_name = member.full_name;
          target.assignee_profile_picture = member.profile_picture;
          target.assigner_id = userDetails.user_id;
          target.assignee_inactive = member.inactive;
        } else {
          target.assignee_email = null;
          target.assignee_id = null;
          target.assignee_name = null;
          target.assignee_profile_picture = null;
          if (target.due_date === null) {
            target.block_status_id = 1;
          }
          target.assignee_inactive = false;
        }

        // blockDetails.splice(targetIndex, 1, target);
        blockDetails[targetIndex] = target;

        if (setGridListDetails) {
          setGridListDetails({
            ...gridListDetails,
            grid_list_details: {
              ...gridListDetails.grid_list_details,
              block_details: groupGridBlock(
                // TODO: Review this function & optimize
                blockDetails,
                gridListDetails.grid_list_details!.task_details!,
              ) as Array<IGridListBlockDetail[]>,
            },
          } as IGridList);
        }

        setShowDropDown && setShowDropDown(0);
        if (updateBlock) {
          updateBlock(target);
        }
      }
    }

    if (contextType === ContextTypes.TASKLIST) {
      setShowAssigneeDropdown(false);
      if (typeof eachTaskDetail?.task_id === 'string') {
        message.info(taskListCmsData?.error_message_options?.no_task_name, 3);
        return;
      }
      if (setEachTaskDetail) {
        let eachTaskDetailUpdated = {
          ...eachTaskDetail,
          assignee_id: member?.user_id,
          assignee_name: member?.full_name,
          assignee_email: member?.email,
          assignee_dp: member?.profile_picture ?? '',
          assigner_id: +userDetails?.user_id!,
          assignee_inactive: member?.inactive,
        };
        findTaskIdAndUpdateMainTaskList &&
          findTaskIdAndUpdateMainTaskList(eachTaskDetailUpdated);

        setEachTaskDetail((prev) => ({
          ...prev,
          assignee_id: member?.user_id,
          assignee_name: member?.full_name ?? undefined,
          assignee_email: member?.email,
          assignee_dp: member?.profile_picture ?? '',
          assigner_id: +userDetails?.user_id!,
          assignee_inactive: member?.inactive,
        }));
      }

      if (
        projectDetails?.project_id &&
        typeof sectionId === 'number' &&
        userDetails?.organization_info
      ) {
        try {
          if (eachTaskDetail) {
            await projectService.updateTaskV2({
              organizationId: getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_id!,
              project_id: projectDetails.project_id,
              section_id: sectionId,
              section_details: [
                {
                  ...eachTaskDetail,
                  task_id: +eachTaskDetail.task_id,
                  assignee_id: member?.user_id,
                  assigner_id: +userDetails?.user_id!,
                },
              ],
              updateAssignee: true,
            });
            if (notifyChannel) {
              notifyChannel(
                userDetails,
                NotificationEventType.UPDATE_SIMPLE_TASK_ROW,
                JSON.stringify({
                  ...eachTaskDetail,
                  task_id: eachTaskDetail.task_id,
                  assignee_id: member?.user_id,
                  assignee_email: projectMembers?.find(
                    (ele) => ele.user_id === member?.user_id,
                  )?.email,
                  assignee_name: projectMembers?.find(
                    (ele) => ele.user_id === member?.user_id,
                  )?.full_name,
                }),
              );
            }
          }
        } catch (error: any) {
          if (error?.response?.data?.messageId === -4) {
            fetchNewPermission(
              org_key,
              taskListCmsData?.lbl_error_message_permission_denied,
            );
            return;
          }
          message.error(taskListCmsData?.error_message_options?.msg, 3);
        }
      }
    }
  };

  const getUserAvatarJsx = (eachMember: IProjectMember) => {
    if (!eachMember.inactive) {
      return eachMember.profile_picture ? (
        <img src={eachMember.profile_picture} alt="" />
      ) : (
        getUserGravatar(eachMember.full_name ?? eachMember.email)
      );
    }
    return null;
  };

  const getUserEmailClassName = (eachMember: IProjectMember) => {
    if (eachMember.inactive) {
      return 'userAvtrBlankContent';
    }
    return 'userEmail';
  };

  const getUserAvatarClassName = (eachMember: IProjectMember) => {
    if (!eachMember.inactive) {
      return eachMember.profile_picture ? 'userAvtrImg' : '';
    }
    return 'userAvtrBlank';
  };

  const getAssigneeListJsx = () => {
    return searchMembers
      ?.filter(
        (eachMember) => eachMember.email !== assigneeDetails?.assignee_email,
      )
      .map((eachMember) => {
        return (
          <li
            key={eachMember.user_id}
            onClick={() => setAssignee(eachMember)}
            className={
              eachMember.user_id === assigneeDetails?.assignee_id
                ? 'active'
                : ''
            }
          >
            <div className="userBlkWrap">
              <div className="userPart">
                <div
                  className={`userAvtr ${getUserAvatarClassName(eachMember)}`}
                >
                  {getUserAvatarJsx(eachMember)}
                </div>
                <div className="userName-detail">
                  {eachMember.inactive ? (
                    <></>
                  ) : (
                    <div className="userName">
                      {userDetails.email === eachMember.email
                        ? eachMember.full_name +
                          `\u0020(${taskListCmsData?.task_assignee_custom_text[1]})`
                        : eachMember.full_name}
                    </div>
                  )}
                  <div className={getUserEmailClassName(eachMember)}>
                    {eachMember.email}
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      });
  };

  const Content = () => {
    return (
      <div className="gridAssgineeList">
        <ul>
          <li>
            <div className="userBlkWrap">
              <Input
                // key={randomKey}
                onInput={(e: any) => {
                  const value = e.target.value.trim();
                  if (!value)
                    setSearchMembers(
                      projectMembers?.filter(
                        (eachMember) =>
                          eachMember.email !== eachTaskDetail?.assignee_email,
                      ) ?? null,
                    );
                  else {
                    setSearchMembers(
                      projectMembers?.filter(
                        (eachMember) =>
                          eachMember.email !== eachTaskDetail?.assignee_email &&
                          (eachMember.full_name
                            ?.toLowerCase()
                            .includes(value.toLowerCase()) ||
                            eachMember.email
                              ?.toLowerCase()
                              .includes(value.toLowerCase())),
                      ) ?? [],
                    );
                  }
                  setSearchInput(e.target.value);
                }}
                size="small"
                placeholder={
                  gridListCmsData?.lbl_search_project_member ??
                  'Search project member'
                }
                prefix={<SearchOutlined />}
                value={searchInput}
                autoFocus
              />
            </div>
          </li>
          <li
            className="unassignedTask"
            onClick={() => setAssignee(null)}
            onKeyDown={() => {}}
          >
            <div className="userBlkWrap">
              <div className="userPart">
                <div className="userAvtr">
                  <span className="cmnIcon unassign"></span>
                </div>
                <div className="userName">
                  {taskListCmsData?.task_assignee_custom_text[0]}
                </div>
              </div>
            </div>
          </li>
          {getAssigneeListJsx()}
        </ul>
        <span
          className="closeAssigneeList cmnIcon closeIconn"
          onClick={() => {
            if (contextType === ContextTypes.GRIDLIST) {
              setShowDropDown && setShowDropDown(0);
            } else {
              setShowAssigneeDropdown(false);
            }
          }}
          onKeyDown={() => {}}
        ></span>
      </div>
    );
  };

  const openingLogic = () => {
    if (currentSection) {
      return (
        showAssigneeDropdown &&
        projectDetails?.is_archived === false &&
        currentSection?.sectionId === Number(eachTaskDetail?.task_id) &&
        currentSection?.popup === 'assignee'
      );
    } else {
      return showAssigneeDropdown;
    }
  };

  const getPopoverUserAvatarClassName = () => {
    if (eachTaskDetail?.assignee_inactive) {
      return 'userAvtrBlank';
    }
    return eachTaskDetail?.assignee_dp ? 'userAvtrImg' : '';
  };

  const getPopoverUserAvatarJsx = () => {
    if (eachTaskDetail?.assignee_inactive) {
      return null;
    }
    return eachTaskDetail?.assignee_dp ? (
      <img alt="user avatar" src={eachTaskDetail?.assignee_dp}></img>
    ) : (
      getUserGravatar(
        eachTaskDetail?.assignee_name ?? eachTaskDetail?.assignee_email!,
      )
    );
  };

  const getPopoverUserNameClassName = () => {
    if (eachTaskDetail?.assignee_inactive) {
      return 'userAvtrBlankContent';
    }
    return eachTaskDetail?.assignee_name ? 'userName' : '';
  };

  const getPopoverUserName = () => {
    if (eachTaskDetail?.assignee_inactive) {
      return eachTaskDetail?.assignee_email;
    } else if (userDetails?.email === eachTaskDetail?.assignee_email) {
      return (
        eachTaskDetail?.assignee_name +
        `\u0020(${taskListCmsData?.task_assignee_custom_text[1]})`
      );
    } else {
      return eachTaskDetail?.assignee_name;
    }
  };

  const contentForTaskList = () => {
    if (
      eachTaskDetail &&
      eachTaskDetail.assignee_id !== null &&
      eachTaskDetail.assignee_id !== undefined
    ) {
      return (
        <Tooltip
          title={getPopoverUserName()}
          color={'#2E364C'}
          placement="bottom"
        >
          <Popover
            placement="bottom"
            content={<Content />}
            trigger="click"
            overlayClassName="gridAssigneeListPopOver"
            open={openingLogic()}
            onOpenChange={(event) => {
              if (event === false) {
                setShowAssigneeDropdown(false);
              } else if (
                hasPermissions(
                  [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                  projectDetails?.associated_role_id,
                ) &&
                eachTaskDetail?.assignee_id !== undefined &&
                projectDetails?.is_archived === false
              ) {
                setShowAssigneeDropdown(true);
              } else {
                setShowAssigneeDropdown(false);
              }
            }}
          >
            <div>
              <div
                className="userBlkWrap"
                onClick={() => {
                  if (
                    projectDetails?.is_archived === false &&
                    hasPermissions(
                      [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                      projectDetails?.associated_role_id,
                    )
                  ) {
                    if (showAssigneeDropdown === false) {
                      setShowAssigneeDropdown(true);
                    } else {
                      setShowAssigneeDropdown(false);
                    }
                  }
                }}
              >
                <div className={`userAvtr ${getPopoverUserAvatarClassName()}`}>
                  {getPopoverUserAvatarJsx()}
                </div>

                <div className={getPopoverUserNameClassName()}>
                  {getPopoverUserName()}
                </div>
              </div>
            </div>
          </Popover>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          title={taskListCmsData?.task_assignee_custom_text[0]}
          color={'#2E364C'}
          placement="bottom"
        >
          <Popover
            placement="bottom"
            content={<Content />}
            trigger="click"
            overlayClassName="gridAssigneeListPopOver"
            open={openingLogic()}
            onOpenChange={(event) => {
              if (event === false) {
                setShowAssigneeDropdown(false);
              } else {
                if (
                  hasPermissions(
                    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                    projectDetails?.associated_role_id,
                  )
                ) {
                  if (
                    eachTaskDetail &&
                    eachTaskDetail.assignee_id !== undefined &&
                    projectDetails?.is_archived === false
                  ) {
                    setShowAssigneeDropdown(true);
                  } else {
                    setShowAssigneeDropdown(false);
                  }
                }
              }
            }}
          >
            <div
              className="unassignUserBlkWrap userBlkWrap"
              onClick={() => {
                if (
                  projectDetails?.is_archived === false &&
                  hasPermissions(
                    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                    projectDetails?.associated_role_id,
                  )
                ) {
                  if (showAssigneeDropdown === false) {
                    setShowAssigneeDropdown(true);
                  } else {
                    setShowAssigneeDropdown(false);
                  }
                }
              }}
              onKeyDown={() => {}}
            >
              <span className="unassign"></span>

              <div className="userName textMuted">
                {taskListCmsData?.task_assignee_custom_text[0]}
              </div>
            </div>
          </Popover>
        </Tooltip>
      );
    }
  };

  const getGridListAssigneeBlockClassName = () => {
    let classNameString = 'blkAvtr ';
    if (
      !blockDetails?.assignee_profile_picture &&
      !blockDetails?.assignee_name
    ) {
      classNameString += 'blkNoAvtr ';
    }
    if (
      blockDetails?.assignee_email &&
      blockDetails?.assignee_inactive &&
      ![2].includes(gridListDetails?.view_id ?? 0)
    ) {
      classNameString += 'userAvtrBlank';
    }
    return classNameString;
  };
  return (
    <Fragment>
      {contextType === ContextTypes.GRIDLIST ? (
        gridListDetails?.compactView ? (
          <Content />
        ) : (
          <Tooltip
            title={
              blockDetails?.assignee_inactive
                ? blockDetails?.assignee_email
                : blockDetails?.assignee_name
            }
            color={'#2E364C'}
            placement="left"
          >
            <Popover
              placement="bottom"
              content={<Content />}
              trigger="click"
              overlayClassName="gridAssigneeListPopOver"
              open={
                showDropDown === blockId &&
                projectDetails?.is_archived === false
              }
              onOpenChange={() => {
                if (
                  hasPermissions(
                    [ERbacPermissions.PROJECT_SECTION_GRIDLIST_BLOCK_EDIT],
                    projectDetails?.associated_role_id,
                  )
                ) {
                  if (
                    blockId !== undefined &&
                    blockId !== showDropDown &&
                    projectDetails?.is_archived === false
                  ) {
                    setShowDropDown && setShowDropDown(blockId);
                  } else {
                    setShowDropDown && setShowDropDown(0);
                  }
                }
              }}
            >
              <div
                onClick={() => {
                  if (
                    hasPermissions(
                      [ERbacPermissions.PROJECT_SECTION_GRIDLIST_BLOCK_EDIT],
                      projectDetails?.associated_role_id,
                    )
                  ) {
                    if (
                      blockId !== undefined &&
                      blockId !== showDropDown &&
                      !projectDetails?.is_archived
                    ) {
                      setShowDropDown && setShowDropDown(blockId);
                    } else {
                      setShowDropDown && setShowDropDown(0);
                    }
                  }
                }}
                onKeyDown={() => {}}
                className={getGridListAssigneeBlockClassName()}
              >
                {[2, 3, 4].includes(gridListDetails?.view_id ?? 0) ? (
                  <div>
                    {blockDetails?.assignee_inactive &&
                    gridListDetails?.view_id !== 2
                      ? null
                      : getUserGravatar(
                          blockDetails?.assignee_name ??
                            blockDetails?.assignee_email!,
                        )}
                  </div>
                ) : (
                  <>
                    {!blockDetails?.assignee_inactive &&
                    blockDetails?.assignee_profile_picture ? (
                      <img
                        src={
                          blockDetails?.assignee_profile_picture ?? ProfilePic
                        }
                        alt=""
                      />
                    ) : (
                      <div>
                        {!blockDetails?.assignee_inactive &&
                          getUserGravatar(blockDetails?.assignee_name ?? '')}
                      </div>
                    )}
                  </>
                )}
              </div>
            </Popover>
          </Tooltip>
        )
      ) : viewType && viewType === TaskListView.CARD ? (
        contentForTaskList()
      ) : (
        <td className="assignWrap">{contentForTaskList()}</td>
      )}
    </Fragment>
  );
};

export default SharedAssigneeDropDown;
