import { WarningOutlined } from '@ant-design/icons/lib/icons';
import cryptoRandomString from 'crypto-random-string';
import { IProjectState, ITaskList } from 'models/interface';
import {
  useLocalizationStore,
  useProjectDashboardStore,
  useUserStore,
} from 'stores';
import { getPageSpecificData } from 'utils/commonFunctions';

interface IAddTask {
  taskDetails?: ITaskList;
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList>>;
}

const AddTask: React.FC<IAddTask> = ({ taskDetails, setTaskListDetails }) => {
  const { cmsData } = useLocalizationStore((state: any) => state);
  const { taskListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { userDetails } = useUserStore((state: any) => state);

  const addTask = () => {
    const _task_list_details = [...(taskDetails?.task_list_details ?? [])];
    let max = 0;
    const newTask = {
      task_id: cryptoRandomString({ length: 5 }),
      task_rank: max + 1,
      task_name: '',
      due_date: '',
      assignee_dp: '',
      assignee_id: null,
      assignee_name: '',
      assignee_email: '',
      task_status_id: 1,
      task_priority_id: 2,
      task_status_name: taskListCmsData?.task_status_options.find(
        (eachOption) => eachOption.status_id === 1,
      )?.status_name,
      task_priority_name: taskListCmsData?.task_priority_options.find(
        (eachOption) => eachOption.priority_id === 2,
      )?.priority_name,
      created_by: userDetails?.user_id,
      show: true,
    };
    if (taskDetails?.task_list_details?.length) {
      let emptyTask = taskDetails?.task_list_details?.findIndex(
        (ele) => ele?.task_name?.trim() === '',
      );
      if (emptyTask && emptyTask < 0) {
        max = taskDetails?.task_list_details?.reduce(function (prev, current) {
          return prev?.task_rank > current?.task_rank ? prev : current;
        }).task_rank;

        _task_list_details.push(newTask);
        setTaskListDetails((prev) => ({
          ...prev,
          task_list_details: _task_list_details,
        }));
      }
      return;
    }
    setTaskListDetails((prev) => ({
      ...prev,
      task_list_details: [newTask],
    }));
  };

  const throwAlert = () => {
    let pageSpecificData = cmsData.pageSpecificData;
    let tmpPageData: any;
    if (pageSpecificData.length > 0) {
      tmpPageData = getPageSpecificData(cmsData, 'task-list');
    }
    let newEmptyTaskPresent = taskDetails?.task_list_details?.find(
      (itm) => typeof itm.task_id === 'string',
    );
    if (
      taskDetails?.show_own_tasks === true &&
      newEmptyTaskPresent !== undefined
    ) {
      if (tmpPageData !== undefined) {
        let txt = (
          <div className="filterWarningAddTask">
            <WarningOutlined />
            {tmpPageData[0].attributes?.lbl_warning_filter_applied}
          </div>
        );

        return txt;
      }
    }
  };

  return (
    <div className="addTask">
      <div
        className="itemNameBlk addFolder"
        onClick={() => addTask()}
        onKeyDown={() => {}}
      >
        <span className="addPlusSolid" />
        <span className="itemName">{taskListCmsData?.btn_add_task.name}</span>
        {/* <span id={`filter-warning-${taskDetails?.section_id}`}>
          {throwAlert()}
        </span> */}
      </div>
    </div>
  );
};

export default AddTask;
