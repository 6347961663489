import React, { useEffect, useState } from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import { useViewport, useReactFlow } from 'reactflow';
import { loggerService } from 'services';
import feedbackService from 'services/pncService/feedback.service';
import { useFeedbackStore } from 'stores/feedback.store';
import { message, Popover } from 'antd';
import {
  collectFullNames,
  convertNamesToInitials,
  getCurrentOrganization,
} from 'utils/commonFunctions';
import trashIcon from 'assets/images/delete-bin.svg';
import FeedBackPopup from './FeedBackPopup';
import {
  IFeedbackDetailsData,
  IFeedbackList,
  IGetFeedbackbyIdPayload,
  IPageData,
} from 'models/interface/pnc.interface';
import CustomVideoContainer from './CustomVideoContainer';
import FeedBackBubble from './FeedBackBubble';
import { useLoaderStore, useProjectDashboardStore, useUserStore } from 'stores';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';
import { useModalStore } from 'stores/modal.store';
import { IModalState } from 'models/interface';
import FeedbackMessageBox from './FeedbackMessageBox';
import { useParams } from 'react-router-dom';

function ScreenshotContainer() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [dragMarkAreaPosition, setDragMarkAreaPosition] = useState<any>();
  const [existingFeedback, setExistingFeedback] = useState<
    IFeedbackDetailsData[]
  >([]);
  const [isEditingScreenshotTitle, setIsEditingScreenshotTitle] =
    useState(false);
  const [activeTitleIndex, setActiveTitleIndex] = useState<string>('');
  const [activeDragImageMouseDown, setActiveDragImageMouseDown] =
    useState<string>();
  const org_key = location.pathname.split('/')[2];
  const [activeDragImage, setActiveDragImage] = useState<string>('');
  const { zoom } = useViewport();
  const { zoomTo, setViewport } = useReactFlow();
  const [isLoadingFeedbackData, setIsLoadingFeedbackData] =
    useState<boolean>(false);
  const {
    feedbackList,
    setFeedbackList,
    panOnDrag,
    handleSetPanOnDrag,
    setSavePayload,
    zoomLevelPercentage,
    updateZoomLevel,
    filterKey,
    updateFilterValue,
    envValue,
    fitviewFlag,
    updateFitviewFlag,
    updateScreenTitle,
    removeFeedback,
    setIsRightDrawerOpen,
  } = useFeedbackStore((state: any) => state);
  const { projectDetails } = useProjectDashboardStore();
  const { userDetails } = useUserStore((state: any) => state);
  const { setLoaderState } = useLoaderStore();
  const { pncFeedbackSpecificCmsData, pncAddEnvSpecificCmsData } =
    usePncCmsDataStore();
  const [originalScreenShotTitle, setOriginalScreenShotTitle] =
    useState<string>('');
  const { modalState, setModalState } = useModalStore(
    (state: IModalState) => state,
  );
  const urlParams = useParams();

  const fetchFeedbackList = async (
    feedbackStatusKey: number,
    env_id: number,
  ) => {
    try {
      setLoaderState('active');
      const payload = {
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        projectId: Number(urlParams?.projectId),
        feedbackStatusId: feedbackStatusKey,
        environmentId: env_id,
      };

      const response: any = await feedbackService.getAllFeedbacks(payload);
      if (response?.messageId === 1) {
        setFeedbackList(response?.data?.feedback_data);
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_all_fb,
        );
      }
    } catch (err) {
      message.error(pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_all_fb);
      await loggerService.log({
        severity: 'High',
        message: `PNC get all feedbacks action failed`,
        payload: { ApiResponse: err },
      });
    } finally {
      setLoaderState('inactive');
    }
  };

  const handleGiveFeedBack = (
    event: React.MouseEvent<HTMLDivElement>,
    id: string,
  ) => {
    const target = event.target as HTMLElement;
    if (
      !(target.nodeName === 'SPAN' || target.nodeName === 'DIV') &&
      target.className === 'ssImage'
    ) {
      handlePanelClose();
      setActiveDragImageMouseDown(id);

      const rect = event.currentTarget.getBoundingClientRect();
      const currentParentDiv = document.getElementById(id);

      setDragMarkAreaPosition({
        top: ((event.clientY - rect.top) * 100) / rect.height,
        left: ((event.clientX - rect.left) * 100) / rect.width,
        height: 0,
        width: 0,
      });

      let deltaX = 0;
      let deltaY = 0;
      let dimensionData = {
        top: ((event.clientY - rect.top) * 100) / rect.height,
        left: ((event.clientX - rect.left) * 100) / rect.width,
        height: 0,
        width: 0,
      };

      const handleMouseMove = (moveEvent: MouseEvent) => {
        const currentX = moveEvent.clientX - rect.left;
        const currentY = moveEvent.clientY - rect.top;

        deltaX = currentX - (event.clientX - rect.left);
        deltaY = currentY - (event.clientY - rect.top);

        if (deltaX >= 0 && deltaY < 0) {
          dimensionData = {
            top: ((event.clientY - rect.top + deltaY) * 100) / rect.height,
            left: ((event.clientX - rect.left) * 100) / rect.width,
            height: (Math.abs(deltaY) * 100) / rect.height,
            width: (Math.abs(deltaX) * 100) / rect.width,
          };
          setDragMarkAreaPosition(dimensionData);
        } else if (deltaX < 0 && deltaY >= 0) {
          dimensionData = {
            top: ((event.clientY - rect.top) * 100) / rect.height,
            left: ((event.clientX - rect.left + deltaX) * 100) / rect.width,
            height: (Math.abs(deltaY) * 100) / rect.height,
            width: (Math.abs(deltaX) * 100) / rect.width,
          };
          setDragMarkAreaPosition(dimensionData);
        } else if (deltaX < 0 && deltaY < 0) {
          dimensionData = {
            top: ((event.clientY - rect.top + deltaY) * 100) / rect.height,
            left: ((event.clientX - rect.left + deltaX) * 100) / rect.width,
            height: (Math.abs(deltaY) * 100) / rect.height,
            width: (Math.abs(deltaX) * 100) / rect.width,
          };
          setDragMarkAreaPosition(dimensionData);
        } else {
          dimensionData = {
            top: ((event.clientY - rect.top) * 100) / rect.height,
            left: ((event.clientX - rect.left) * 100) / rect.width,
            height: (Math.abs(deltaY) * 100) / rect.height,
            width: (Math.abs(deltaX) * 100) / rect.width,
          };
          setDragMarkAreaPosition(dimensionData);
        }
      };

      const handleMouseUp = (MouseUpevent: MouseEvent) => {
        currentParentDiv?.removeEventListener('mousemove', handleMouseMove);
        currentParentDiv?.removeEventListener('mouseup', handleMouseUp);
        setActiveDragImage(id);
        setSavePayload({
          feedbackId: id,
          top: dimensionData?.top,
          left: dimensionData?.left,
          width: dimensionData?.width,
          height: dimensionData?.height,
        });
      };
      currentParentDiv?.addEventListener('mousemove', handleMouseMove);
      currentParentDiv?.addEventListener('mouseup', handleMouseUp);
    }
  };

  const getFbById = async (payload: IGetFeedbackbyIdPayload) => {
    try {
      setIsLoadingFeedbackData(true);
      const response: any = await feedbackService.getFeedbacksById(payload);
      if (response.messageId === 1) {
        setExistingFeedback(response?.data?.feedback_data);
      } else {
        console.log(response);
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_details_by_id,
        );
        handlePanelClose();
        setIsRightDrawerOpen(false);
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_details_by_id,
      );
      handlePanelClose();
      setIsRightDrawerOpen(false);
    } finally {
      setIsLoadingFeedbackData(false);
    }
  };

  const handleClickOnFbMarker = async (detailsid: number) => {
    setActiveIndex(detailsid);
    try {
      const payload = {
        organizationId: projectDetails?.organization_id,
        projectId: projectDetails?.project_id,
        detailsId: detailsid,
      };
      detailsid && (await getFbById(payload));
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_details_by_id,
      );
    }
  };

  const closeEditorPopup = () => {
    setActiveDragImageMouseDown('');
    setDragMarkAreaPosition(null);
    setActiveDragImage('');
    setActiveIndex(null);
  };

  const handlePanelClose = () => {
    setDragMarkAreaPosition(null);
    setActiveDragImage('');
    setActiveIndex(null);
    setExistingFeedback([]);
  };

  const handleScreenTitle = async (
    feedbackIdValue: number,
    value: string | undefined = undefined,
  ) => {
    if (!value || value.trim() === originalScreenShotTitle) {
      setActiveTitleIndex('-1');
      setOriginalScreenShotTitle('');
    } else {
      const title = value.trim();
      const payload = {
        organizationId: projectDetails?.organization_id,
        projectId: projectDetails?.project_id,
        feedbackId: feedbackIdValue,
        ScreenName: title,
      };
      try {
        const response = await feedbackService.editFeedbackScreen(payload);
        if (response.messageId === 1) {
          updateScreenTitle(feedbackIdValue, title);
        } else {
          console.log(response);
          message.error(
            pncFeedbackSpecificCmsData?.toaster_msgs?.error_save_screen_name,
          );
        }
      } catch (error) {
        console.log(error);
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_save_screen_name,
        );
      } finally {
        setActiveTitleIndex('-1');
        setOriginalScreenShotTitle('');
      }
    }
  };
  const handleDeleteModalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
  };

  const DeleteScreen = async (feedbackId: number) => {
    handleDeleteModalClose();
    setLoaderState('active');
    try {
      const payload = {
        organizationId: projectDetails?.organization_id,
        projectId: projectDetails?.project_id,
        feedbackId: feedbackId,
        deletedBy: userDetails?.user_id,
      };
      const response = await feedbackService.deleteScreen(payload);
      if (response.messageId === 1) {
        removeFeedback(feedbackId);
        handleDeleteModalClose();
        message.success(
          pncFeedbackSpecificCmsData?.toaster_msgs?.success_del_fb,
        );
      } else {
        console.log(response);
        message.error(pncFeedbackSpecificCmsData?.toaster_msgs?.error_del_fb);
      }
    } catch (error) {
      console.log(error);
      message.error(pncFeedbackSpecificCmsData?.toaster_msgs?.error_del_fb);
    } finally {
      setLoaderState('inactive');
    }
  };

  const handleDeleteScreen = async (
    e: any,
    id: number,
    screen: any,
    screenName: string,
  ) => {
    e.stopPropagation();
    handlePanelClose();

    setModalState({
      modalContentClassName: 'deleteEnvModal',
      modalHeader: (
        <>
          <h3>{`${pncAddEnvSpecificCmsData?.del_env_modal_heading_pre} ${screenName}`}</h3>
          <span
            className="material-icons-outlined modal-close"
            onClick={() => handleDeleteModalClose()}
          >
            close
          </span>
        </>
      ),
      modalBody: (
        <>
          {screen.includes('png') ? (
            <img
              src={screen}
              className="image"
              draggable="false"
              alt=""
              loading="lazy"
            />
          ) : (
            <video>
              <source src={screen} type="video/webm"></source>
              <track kind="captions" />
            </video>
          )}
          <p>{`${pncFeedbackSpecificCmsData?.del_ss_modal_body}`}</p>
        </>
      ),
      modalFooter: (
        <>
          <button
            className="noStyle cancel_deleteFolder"
            type="button"
            onClick={() => handleDeleteModalClose()}
          >
            {pncFeedbackSpecificCmsData?.btn_cta_cancel}
          </button>

          <button
            className="deleteBtnStyle"
            type="button"
            onClick={() =>
              (async () => {
                await DeleteScreen(id);
              })()
            }
          >
            {pncFeedbackSpecificCmsData?.btn_cta_confirm}
          </button>
        </>
      ),
      shouldShow: true,
    });
  };

  useEffect(() => {
    if (envValue && envValue !== 0) {
      fetchFeedbackList(filterKey, envValue);
    }
  }, [filterKey, envValue]);

  useEffect(() => {
    if (activeIndex) {
      setDragMarkAreaPosition(null);
    }
  }, [activeIndex]);

  useEffect(() => {
    zoomTo(zoomLevelPercentage);
  }, [zoomLevelPercentage]);

  useEffect(() => {
    if (fitviewFlag) setViewport({ x: 0, y: 0, zoom: 0.4 });
    updateFitviewFlag(0);
  }, [fitviewFlag]);

  useEffect(() => {
    updateFitviewFlag(0);
    updateZoomLevel(zoom);
  }, [zoom]);

  useEffect(() => {
    closeEditorPopup();
    return () => {
      closeEditorPopup();
      if (!panOnDrag) handleSetPanOnDrag(true);
    };
  }, [panOnDrag, envValue, filterKey]);

  useEffect(() => {
    return () => {
      updateFilterValue(0);
      setViewport({ x: 0, y: 0, zoom: 0.4 });
    };
  }, [envValue]);

  return (
    <>
      {feedbackList?.map((feedbackListEle: IFeedbackList) => (
        <ul key={feedbackListEle.page_id} className="imageRow ">
          <li>
            <div className="taskNameHeadingPane">
              <div className="itemNameBlk">
                <div
                  className="nameOuterBlk"
                  style={{
                    backgroundColor: `${projectDetails?.project_color}`,
                  }}
                >
                  <span className="section-text-span">
                    {feedbackListEle.page_title}
                  </span>
                </div>
              </div>
            </div>
            <div className="pncUrl">{feedbackListEle.page_url}</div>
            <div className="dividerHorizontal">
              <hr></hr>
            </div>

            <div
              className={`wrapImage ${panOnDrag ? 'custom-drag-handle' : ''}`}
            >
              {feedbackListEle.page_data.map(
                (pageDataEle: IPageData, pageDataIndex: number) => (
                  <div
                    key={pageDataEle.feedback_id}
                    className={`imageContainer ${
                      !panOnDrag ? 'feedBackActive' : ''
                    }`}
                  >
                    <div className="screenTitleWrap">
                      {activeTitleIndex !==
                      pageDataEle.feedback_id.toString() ? (
                        <h3
                          className="screenTitle"
                          onClick={() => {
                            setIsEditingScreenshotTitle(
                              !isEditingScreenshotTitle,
                            );
                            setActiveTitleIndex(
                              pageDataEle.feedback_id.toString(),
                            );
                            setOriginalScreenShotTitle(
                              pageDataEle.screen_name || '',
                            );
                          }}
                        >
                          {pageDataEle.screen_name || ''}
                        </h3>
                      ) : (
                        <input
                          onBlur={(e) => {
                            handleScreenTitle(
                              pageDataEle.feedback_id,
                              e.target.value,
                            );
                          }}
                          type="text"
                          className="screenTitle"
                          autoFocus={true}
                          defaultValue={pageDataEle.screen_name || ''}
                          maxLength={50}
                        ></input>
                      )}
                    </div>
                    <div
                      className="imageMainDiv"
                      style={
                        pageDataEle.screenshot_url.includes('png')
                          ? {
                              width: `${pageDataEle.screen_width}px`,
                              height: `${pageDataEle.screen_height}px`,
                            }
                          : {
                              width: `${pageDataEle.screen_width}px`,
                            }
                      }
                      onMouseDown={(event) =>
                        !panOnDrag &&
                        !activeDragImage &&
                        pageDataEle.screenshot_url.includes('png')
                          ? handleGiveFeedBack(
                              event,
                              pageDataEle.feedback_id.toString(),
                            )
                          : null
                      }
                      id={pageDataEle.feedback_id.toString()}
                    >
                      {dragMarkAreaPosition &&
                        activeDragImageMouseDown ===
                          pageDataEle.feedback_id.toString() && (
                          <div
                            className="markerForFeedback"
                            style={{
                              top: `${dragMarkAreaPosition.top}%`,
                              left: `${dragMarkAreaPosition.left}%`,
                              width: `${dragMarkAreaPosition.width}%`,
                              height: `${dragMarkAreaPosition.height}%`,
                            }}
                          >
                            <Popover
                              content={
                                pageDataEle.feedback_id.toString() ===
                                  activeDragImage &&
                                !activeIndex && (
                                  <FeedBackPopup
                                    closeEditorPopup={() => closeEditorPopup()}
                                  />
                                )
                              }
                              overlayClassName="feedbackPopover commentContainer"
                              placement="bottomLeft"
                              trigger={'click'}
                              open={true}
                              arrow={false}
                            >
                              {activeDragImage ===
                                pageDataEle.feedback_id.toString() && (
                                <div
                                  style={{
                                    transform: `scale(${1 / zoom})`,
                                  }}
                                  className="commentBubbleIcon"
                                ></div>
                              )}
                            </Popover>
                          </div>
                        )}

                      {pageDataEle.screenshot_url.includes('png') ? (
                        <img
                          src={pageDataEle.screenshot_url}
                          className="ssImage"
                          draggable="false"
                          alt=""
                          loading="lazy"
                        />
                      ) : (
                        <CustomVideoContainer
                          src={pageDataEle?.screenshot_url}
                          feedbackDetailsId={
                            pageDataEle?.feedback_details_data[0]?.details_id
                          }
                          feedbackId={pageDataEle.feedback_id}
                          onClick={handlePanelClose}
                        />
                      )}
                      {pageDataEle?.feedback_details_data?.map(
                        (fbposition: IFeedbackDetailsData) => {
                          const fullNames = collectFullNames(fbposition);
                          const initialNameArray =
                            convertNamesToInitials(fullNames);
                          const isResolved =
                            fbposition?.feedback_status_id === 3;
                          return (
                            <React.Fragment key={fbposition?.details_id}>
                              {pageDataEle.screenshot_url.includes('png') &&
                                (fbposition.details_id === activeIndex ? (
                                  <div
                                    style={{
                                      top: `${fbposition?.feedback_coordinates?.top}%`,
                                      left: `${fbposition?.feedback_coordinates?.left}%`,
                                      width: `${fbposition?.feedback_coordinates?.width}%`,
                                      height: `${fbposition?.feedback_coordinates?.height}%`,
                                    }}
                                    className="markerForFeedback"
                                  >
                                    <Popover
                                      content={
                                        <div className="submittingFeedbackPopupWrap">
                                          <FeedbackMessageBox
                                            feedbackDetailsId={
                                              fbposition.details_id
                                            }
                                            handleCancel={handlePanelClose}
                                            feedbackId={pageDataEle.feedback_id}
                                            isDraggable={true}
                                            feedbackData={existingFeedback}
                                            setFeedbackData={
                                              setExistingFeedback
                                            }
                                            isLoading={isLoadingFeedbackData}
                                          />
                                        </div>
                                      }
                                      overlayClassName="feedbackPopover commentContainer"
                                      placement="bottomLeft"
                                      trigger={'click'}
                                      open={true}
                                      arrow={false}
                                    >
                                      <FeedBackBubble
                                        activeIndex={activeIndex}
                                        positionCordinate={{
                                          top: '-44px',
                                          left: '100%',
                                        }}
                                        initialNameArray={initialNameArray}
                                        isResolved={isResolved}
                                        feedbackDetailsId={
                                          fbposition?.details_id
                                        }
                                        handleClickOnFbMarker={() => {}}
                                        type={'dynamic'}
                                      />
                                    </Popover>
                                  </div>
                                ) : (
                                  <FeedBackBubble
                                    activeIndex={activeIndex!}
                                    positionCordinate={{
                                      top: `calc(${fbposition?.feedback_coordinates?.top}% - 44px)`,
                                      left: `calc(${
                                        Number(
                                          fbposition?.feedback_coordinates
                                            ?.left,
                                        ) +
                                        Number(
                                          fbposition?.feedback_coordinates
                                            ?.width,
                                        )
                                      }%)`,
                                    }}
                                    initialNameArray={initialNameArray}
                                    isResolved={isResolved}
                                    feedbackDetailsId={fbposition?.details_id}
                                    handleClickOnFbMarker={() =>
                                      handleClickOnFbMarker(
                                        fbposition.details_id,
                                      )
                                    }
                                    type={'dynamic'}
                                  />
                                ))}
                            </React.Fragment>
                          );
                        },
                      )}
                      <span
                        onClick={(e) =>
                          handleDeleteScreen(
                            e,
                            pageDataEle.feedback_id,
                            pageDataEle.screenshot_url,
                            pageDataEle.screen_name
                              ? pageDataEle.screen_name
                              : `${
                                  pncFeedbackSpecificCmsData?.canvas_default_screen_name
                                } ${
                                  feedbackListEle?.page_data.length -
                                  pageDataIndex
                                }`,
                          )
                        }
                        className="feedbackElemDel"
                      >
                        <img src={trashIcon} alt="deleteicon" />
                      </span>
                    </div>
                  </div>
                ),
              )}
            </div>
          </li>
        </ul>
      ))}
    </>
  );
}

export default ScreenshotContainer;
