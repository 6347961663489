import React, { useState, useRef, useEffect } from 'react';
import './TabPanel.css'; // Importing CSS for styling
import TaskList from '../Sections/TaskList/TaskList';
import {
  useDashboardStore,
  useProjectDashboardStore,
  useUserStore,
} from 'stores';
import {
  IApiResponseCommon,
  IDashboardStore,
  IProjectSections,
  IProjectState,
  IUserState,
} from 'models/interface';
import MemoPad from '../Sections/MemoPad/MemoPad';
import GridList2 from '../Sections/GridListV4/GridList';
import reportViewIcon from '../../../../../assets/images/reportViewIcon.svg';
import downCircle from '../../../../../assets/images/icon-park-outline_down.svg';
import { Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { colorChanger, getCurrentOrganization } from 'utils/commonFunctions';
import { projectService } from 'services';
import { useRbac } from 'auth/rbac/rbac';
import DraggableCustomDropdown from '../../../../sharedComponents/DraggableCustomDropDown/DraggableCustomDropdown';
import { useSearchParams } from 'react-router-dom';
import PointAndCommentRightDrawer from 'components/pages/PointAndComment/FeedbackBoard/PointAndCommentRightDrawer';
import { useFeedbackStore } from 'stores/feedback.store';
import classNames from 'classnames';

const SCROLL_SPEED = 50;

interface TabPanelProps {
  setCurrentId: React.Dispatch<React.SetStateAction<string>>;
  currentId: string;
  refDiv: React.RefObject<HTMLDivElement>;
  forPnc: boolean;
}

const TabPanel: React.FC<TabPanelProps> = ({
  setCurrentId,
  currentId,
  refDiv,
  forPnc = false,
}) => {
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const tabContainerRef = useRef<HTMLUListElement | null>(null);
  const activeTabRef = useRef<HTMLLIElement | null>(null); // Ref for the active tab
  const [sectionView, setSectionView] = useState<any>(null);

  const [urlSearchParams] = useSearchParams();

  const [disableScrollLeft, setDisableScrollLeft] = useState<boolean>(true);
  const [disableScrollRight, setDisableScrollRight] = useState<boolean>(false);

  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const {
    projectDetails,
    projectActiveTab,
    setProjectActiveTab,
    setProjectDetails,
    taskListCmsData,
  } = useProjectDashboardStore((state: IProjectState) => state);

  const { expandedNavBar } = useDashboardStore(
    (state: IDashboardStore) => state,
  );

  const { userDetails, projectRolePermissionsList } = useUserStore(
    (state: IUserState) => state,
  );

  const { hasPermissions } = useRbac();

  const { isRightDrawerOpen, setIsRightDrawerOpen, rightDrawerPayload } =
    useFeedbackStore((state: any) => state);

  const org_key = location.pathname.split('/')[2];
  const currentOrganization =
    userDetails?.organization_info &&
    getCurrentOrganization(org_key, userDetails?.organization_info);

  const userHasPermissionToMove = hasPermissions(
    ['project:section:move'],
    projectRolePermissionsList?.find(
      (p) => p.project_id === projectDetails?.project_id,
    )?.role_id,
  );

  const checkIfEndTab = (direction: number) => {
    if (!tabContainerRef.current) return;
    const srollLeftWidth = tabContainerRef.current.scrollLeft + 300 * direction;
    setDisableScrollLeft(srollLeftWidth <= 0);
    const maxScrollLeft =
      tabContainerRef.current.scrollWidth - tabContainerRef.current.clientWidth;
    setDisableScrollRight(srollLeftWidth >= maxScrollLeft);
  };

  const scrollLeft = () => {
    tabContainerRef.current?.scrollBy({ left: -300, behavior: 'smooth' });
    checkIfEndTab(-1);
  };

  const scrollRight = () => {
    tabContainerRef.current?.scrollBy({ left: 300, behavior: 'smooth' });
    checkIfEndTab(1);
  };

  const handleWheel = (event: React.WheelEvent<HTMLUListElement>) => {
    if (event.deltaY > 0 && event.deltaX === 0) {
      return scrollRight();
    }
    if (event.deltaY < 0 && event.deltaX === 0) {
      return scrollLeft();
    }
    if (event.deltaX > 0 && event.deltaY === 0) {
      return scrollRight();
    }
    if (event.deltaX < 0 && event.deltaY === 0) {
      return scrollLeft();
    }
  };

  const startScroll = (direction: number) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear the previous interval
    }
    const intervalId = setInterval(() => {
      if (tabContainerRef.current) {
        tabContainerRef.current.scrollLeft += direction * SCROLL_SPEED;
        setDisableScrollLeft(tabContainerRef.current.scrollLeft === 0);
        setDisableScrollRight(
          tabContainerRef.current.scrollLeft ===
            tabContainerRef.current.scrollWidth -
              tabContainerRef.current.clientWidth,
        );
      }
    }, 75);
    intervalRef.current = intervalId;
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLUListElement>) => {
    const rect = tabContainerRef?.current?.getBoundingClientRect();
    if (!rect) return;
    const isAtLeftEdge = event.clientX - rect.left < 50;
    const isAtRightEdge = rect.right - event.clientX < 50;

    if (isAtLeftEdge) {
      startScroll(-1);
    } else if (isAtRightEdge) {
      startScroll(1);
    } else {
      handleMouseLeave();
    }
  };

  const handleMouseLeave = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const updateScrollableState = () => {
    if (tabContainerRef.current) {
      const containerWidth = tabContainerRef.current.clientWidth;
      const tabsWidth = Array.from(tabContainerRef.current.children).reduce(
        (acc, tab) => acc + (tab as HTMLElement).offsetWidth + 3, //minor adjustment
        0,
      );
      setIsScrollable(tabsWidth > containerWidth);
    }
  };

  const filteredSections = () => {
    if (forPnc) {
      return projectDetails?.sections?.filter(
        (section) =>
          section?.pnc_page_id !== null &&
          Number(urlSearchParams.get('env')) === section?.environment_id &&
          section?.task_count > 0,
      );
    }
    return projectDetails?.sections?.filter((section) => !section?.pnc_page_id);
  };

  const sectionToRender = () => {
    const sectionList = filteredSections();
    if (sectionList && sectionList.length > 0) {
      const section = sectionList.find(
        (e) => Number(e.section_id) === projectActiveTab,
      );

      if (section) {
        setSectionView(section);
        return;
      }
      if (forPnc) {
        setSectionView(sectionList[0]);
        setProjectActiveTab(
          sectionList[0]?.section_id
            ? (sectionList[0].section_id as number)
            : 0,
        );
      } else {
        setSectionView(projectDetails?.sections?.[0]);
      }
    }
  };

  const activeTabInlineStyle = (tab: {
    section_id: number;
  }): { backgroundColor: string; borderColor: string } => {
    const defaultColor = projectDetails?.project_color || '#A2CFFE'; // Fallback color

    if (projectActiveTab === tab.section_id && defaultColor) {
      return {
        backgroundColor:
          colorChanger(-0.5, defaultColor.trim(), undefined, true) ||
          defaultColor,
        borderColor:
          colorChanger(-0.5, defaultColor.toString(), undefined, true) ||
          defaultColor,
      };
    } else {
      return {
        backgroundColor: defaultColor,
        borderColor: defaultColor,
      };
    }
  };

  const handleDragEnd = async (result: any) => {
    if (!result.length) return;

    const updatedSections = result.map(
      (section: IProjectSections, index: number) => ({
        ...section,
        section_rank: index + 1,
      }),
    );
    const oldTempSections = projectDetails?.sections;
    setProjectDetails({ ...projectDetails, sections: updatedSections });
    try {
      const updateSectionResponse: IApiResponseCommon | null =
        projectDetails?.project_id && currentOrganization
          ? await projectService.updateSection({
              organizationId: currentOrganization?.organization_id,
              project_id: projectDetails?.project_id,
              sections: updatedSections,
            })
          : null;
      if (updateSectionResponse?.messageId === 1) {
        setProjectDetails({
          ...projectDetails,
          sections: updatedSections,
        });

        // notifyChannel(userDetails, NotificationEventType.REORDER_SECTION); @ts-ignore
      }
    } catch (error: any) {
      console.error(error);
      setProjectDetails({ ...projectDetails, sections: oldTempSections });
    }
  };

  const renderComponet = () => {
    if (sectionView) {
      switch (Number(sectionView?.section_type_id)) {
        case 1:
          return (
            <TaskList
              key={sectionView?.section_id}
              sectionDetails={sectionView}
              projectColor={projectDetails?.project_color}
              refDiv={refDiv}
              currentId={currentId}
              setCurrentId={setCurrentId}
              forPnc={forPnc}
            />
          );
        case 2:
          return (
            <GridList2
              key={sectionView?.section_id}
              sectionDetails={sectionView}
              projectColor={projectDetails?.project_color}
              refDiv={refDiv}
              currentId={currentId}
              setCurrentId={setCurrentId}
            />
          );
        case 3:
          return (
            <MemoPad
              key={sectionView?.section_id}
              currentProjectDetails={sectionView}
              sectionId={sectionView?.section_id}
              projectColor={projectDetails?.project_color}
              refDiv={refDiv}
            />
          );
        default:
          return <></>;
      }
    }
  };

  const customJsx = (option: any) => {
    return forPnc ? (
      <Tooltip color={'#2E364C'} placement="right" title={option.page_url}>
        <span className="dropdownListTabItem">{option.section_name}</span>
      </Tooltip>
    ) : (
      <span className="dropdownListTabItem">{option.section_name}</span>
    );
  };

  useEffect(() => {
    updateScrollableState();
    window.addEventListener('resize', updateScrollableState);

    return () => {
      window.removeEventListener('resize', updateScrollableState);
    };
  }, []);

  useEffect(() => {
    sectionToRender();
  }, [projectDetails?.sections, projectActiveTab]);

  useEffect(() => {
    const disableScrollButtons = () => {
      if (tabContainerRef.current) {
        const container = tabContainerRef.current;

        const lastTab: HTMLLIElement | null =
          container.querySelector('li:last-child');
        const firstTab: HTMLLIElement | null =
          container.querySelector('li:first-child');

        if (firstTab) {
          const firstTabLeft = firstTab.offsetLeft;
          setDisableScrollLeft(
            firstTabLeft >= (tabContainerRef.current?.scrollLeft ?? 0),
          );
        }

        if (lastTab) {
          const lastTabRight = lastTab.offsetLeft + lastTab.offsetWidth;
          setDisableScrollRight(
            lastTabRight <=
              (tabContainerRef.current?.clientWidth ?? 0) +
                (tabContainerRef.current?.scrollLeft ?? 0) +
                114,
          );
        }
      }
    };

    if (activeTabRef.current && tabContainerRef.current) {
      const tab = activeTabRef.current;
      const container = tabContainerRef.current;

      const tabLeft = tab.offsetLeft - 130; //minor adjustments
      const tabRight = tabLeft + tab.offsetWidth;
      const containerScrollLeft = container.scrollLeft;
      const containerRightEdge = containerScrollLeft + container.clientWidth;

      if (tabLeft < containerScrollLeft) {
        container.scrollTo({ left: tabLeft, behavior: 'smooth' });
      } else if (tabRight > containerRightEdge) {
        container.scrollTo({
          left: tabRight - container.clientWidth + 100, //minor adjustments
          behavior: 'smooth',
        });
      }

      setTimeout(() => {
        disableScrollButtons();
      }, 750); // Delay by 750ms to ensure DOM is updated
    }
  }, [projectActiveTab, activeTabRef.current]);

  useEffect(() => {
    setTimeout(() => {
      if (tabContainerRef.current) {
        updateScrollableState();
      }
    }, 150); // Delay by 150ms to ensure DOM is updated
  }, [expandedNavBar, projectDetails?.sections, projectActiveTab]);

  return (
    <>
      <div className="tabbedWidgetOuterArea">
        <div className="iconWrapSection">
          <Tooltip color={'#2E364C'} placement="top" title="Report View">
            <img src={reportViewIcon} alt="" className="disabledElement" />
          </Tooltip>
          <DraggableCustomDropdown
            options={filteredSections()}
            onSelect={(event: any) => setProjectActiveTab(event?.section_id)}
            onDragEnd={handleDragEnd}
            toggleButton={
              <img src={downCircle} alt="" style={{ cursor: 'pointer' }} />
            }
            customJsx={customJsx}
            keyIdentifier={'section_id'}
            objectKey={'section_name'}
            isDragDropDisabled={!userHasPermissionToMove}
            defaultSelected={projectDetails?.sections?.find(
              (e) => Number(e.section_id) === projectActiveTab,
            )}
            selectedColor={projectDetails?.project_color?.trim()}
          />
        </div>

        <div className={classNames('sectionNameTabPanelScrollableArea')}>
          <ul
            className="sectionNameTabPanelScrollableAreaTabs"
            ref={tabContainerRef}
            onWheel={handleWheel}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            {filteredSections()?.map((tab: any) => {
              return forPnc ? (
                <Tooltip
                  color={'#2E364C'}
                  placement="top"
                  title={tab?.page_url}
                >
                  <li
                    key={tab.section_id}
                    className={classNames({
                      active: projectActiveTab === tab.section_id,
                      creatingWidgetTab: !tab?.section_name?.trim()?.length,
                    })}
                    onClick={() => setProjectActiveTab(tab.section_id)}
                    style={activeTabInlineStyle(tab)}
                    ref={
                      projectActiveTab === tab.section_id ? activeTabRef : null
                    }
                  >
                    {tab?.section_name ??
                      taskListCmsData?.lbl_new_section_default_name}
                    {tab.is_private ? (
                      <span className="cmnIcon sectionPrivacy lock" />
                    ) : (
                      <></>
                    )}
                  </li>
                </Tooltip>
              ) : (
                <li
                  key={tab.section_id}
                  className={classNames({
                    active: projectActiveTab === tab.section_id,
                    creatingWidgetTab: !tab?.section_name?.trim()?.length,
                  })}
                  onClick={() => setProjectActiveTab(tab.section_id)}
                  style={activeTabInlineStyle(tab)}
                  ref={
                    projectActiveTab === tab.section_id ? activeTabRef : null
                  }
                >
                  {tab?.section_name ??
                    taskListCmsData?.lbl_new_section_default_name}
                  {tab.is_private ? (
                    <span className="cmnIcon sectionPrivacy lock" />
                  ) : (
                    <></>
                  )}
                </li>
              );
            })}
          </ul>
          {isScrollable ? (
            <div className="scrollButtonWrap">
              <button
                className="scroll-button"
                onClick={scrollLeft}
                disabled={disableScrollLeft}
              >
                <LeftOutlined />
              </button>
              <button
                className="scroll-button"
                onClick={scrollRight}
                disabled={disableScrollRight}
              >
                <RightOutlined />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {renderComponet()}
      {isRightDrawerOpen && (
        <PointAndCommentRightDrawer
          show={isRightDrawerOpen}
          setShow={setIsRightDrawerOpen}
          feedbackId={rightDrawerPayload.feedbackId}
          fbDetailsId={rightDrawerPayload.fbDetailsId}
        />
      )}
    </>
  );
};

export default TabPanel;
