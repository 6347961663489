import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Input, Select, Tooltip } from 'antd';
import { eventEmitter } from '../../../../../../../../services/EventEmitter/eventemitter.service';
import { ClockCircleOutlined } from '@ant-design/icons/lib/icons';
import { useProjectDashboardStore } from 'stores';
import { IProjectState } from 'models/interface';
import { detectDateFormat } from 'utils/commonFunctions';

dayjs.extend(customParseFormat);

interface iDateTimeInputWithToggle {
  dateString: string;
  timeString: string;
  setSelectedDateString?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTimeString?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDate?: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  openCalender?: boolean;
  selectedDate?: Dayjs | null;
}

const DateTimeInputWithToggle: React.FC<iDateTimeInputWithToggle> = ({
  dateString,
  timeString,
  setSelectedDateString,
  setSelectedTimeString,
  setSelectedDate,
  selectedDate,
}) => {
  const [isTimeVisible, setIsTimeVisible] = useState(false);
  const [openTimeDropdown, setOpenTimeDropDown] = useState(false);
  const { customfieldCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const timesList = [
    '12:00 AM',
    '12:15 AM',
    '12:30 AM',
    '12:45 AM',
    '1:00 AM',
    '1:15 AM',
    '1:30 AM',
    '1:45 AM',
    '2:00 AM',
    '2:15 AM',
    '2:30 AM',
    '2:45 AM',
    '3:00 AM',
    '3:15 AM',
    '3:30 AM',
    '3:45 AM',
    '4:00 AM',
    '4:15 AM',
    '4:30 AM',
    '4:45 AM',
    '5:00 AM',
    '5:15 AM',
    '5:30 AM',
    '5:45 AM',
    '6:00 AM',
    '6:15 AM',
    '6:30 AM',
    '6:45 AM',
    '7:00 AM',
    '7:15 AM',
    '7:30 AM',
    '7:45 AM',
    '8:00 AM',
    '8:15 AM',
    '8:30 AM',
    '8:45 AM',
    '9:00 AM',
    '9:15 AM',
    '9:30 AM',
    '9:45 AM',
    '10:00 AM',
    '10:15 AM',
    '10:30 AM',
    '10:45 AM',
    '11:00 AM',
    '11:15 AM',
    '11:30 AM',
    '11:45 AM',
    '12:00 PM',
    '12:15 PM',
    '12:30 PM',
    '12:45 PM',
    '1:00 PM',
    '1:15 PM',
    '1:30 PM',
    '1:45 PM',
    '2:00 PM',
    '2:15 PM',
    '2:30 PM',
    '2:45 PM',
    '3:00 PM',
    '3:15 PM',
    '3:30 PM',
    '3:45 PM',
    '4:00 PM',
    '4:15 PM',
    '4:30 PM',
    '4:45 PM',
    '5:00 PM',
    '5:15 PM',
    '5:30 PM',
    '5:45 PM',
    '6:00 PM',
    '6:15 PM',
    '6:30 PM',
    '6:45 PM',
    '7:00 PM',
    '7:15 PM',
    '7:30 PM',
    '7:45 PM',
    '8:00 PM',
    '8:15 PM',
    '8:30 PM',
    '8:45 PM',
    '9:00 PM',
    '9:15 PM',
    '9:30 PM',
    '9:45 PM',
    '10:00 PM',
    '10:15 PM',
    '10:30 PM',
    '10:45 PM',
    '11:00 PM',
    '11:15 PM',
    '11:30 PM',
    '11:45 PM',
  ];

  const possibleDateFormats = [
    // Hyphen (-) separator
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'MM-DD-YYYY',
    'YYYY-DD-MM',
    'MM-YYYY-DD',
    'DD-YYYY-MM',

    // Slash (/) separator
    'YYYY/MM/DD',
    'DD/MM/YYYY',
    'MM/DD/YYYY',
    'YYYY/DD/MM',
    'MM/YYYY/DD',
    'DD/YYYY/MM',

    // Dot (.) separator
    'YYYY.MM.DD',
    'DD.MM.YYYY',
    'MM.DD.YYYY',
    'YYYY.DD.MM',
    'MM.YYYY.DD',
    'DD.YYYY.MM',
  ];

  const getDateFromKeyword = (keyword: string) => {
    let dateFormat = detectDateFormat() ?? 'DD/MM/YYYY';
    switch (keyword.toLowerCase()) {
      case 'today':
        return dayjs().format(dateFormat); // current date

      case 'tomorrow':
        return dayjs().add(1, 'day').format(dateFormat); // current date + 1 day

      case 'yesterday':
        return dayjs().subtract(1, 'day').format(dateFormat); // current date - 1 day
      default:
        const parsedDate = dayjs(keyword, possibleDateFormats, true);
        if (parsedDate.isValid()) {
          return parsedDate.format(dateFormat);
        } else if (keyword === '') {
          return keyword;
        } else {
          return dayjs().format(dateFormat);
        }
    }
  };

  // Handle date input
  const handleDateChange = () => {
    const finalDate = getDateFromKeyword(dateString);
    setSelectedDateString && setSelectedDateString(finalDate);
    if (finalDate === '') {
      setSelectedDate && setSelectedDate(null);
    } else {
      let dateFormat = detectDateFormat() ?? 'DD/MM/YYYY';
      setSelectedDate && setSelectedDate(dayjs(finalDate, dateFormat));
    }
  };

  const convertOClockOrMillitaryFormat = (value: string) => {
    if (value !== '') {
      const oClockMatch = value.match(/^(\d{1,2})\s*(o'?clock)?$/i);
      if (oClockMatch) {
        const hour = parseInt(oClockMatch[1], 10);
        const formattedTime = dayjs(`${hour}:00`, 'H:mm').format('hh:mm A');
        return formattedTime;
      } else {
        let res = convertMilitaryTimeToAmPm(value);
        return res;
      }
    }
    return value;
  };

  const convertMilitaryTimeToAmPm = (value: string) => {
    if (value !== '') {
      const cleanValue = value.replace(/\D/g, '').slice(0, 4);
      if (cleanValue.length === 4) {
        const parsedTime = dayjs(cleanValue, 'HHmm');
        return parsedTime.format('hh:mm A');
      } else {
        return dayjs().format('hh:mm A');
      }
    }
    return value;
  };

  const checkDateExists = () => {
    if (dateString === '') {
      setSelectedDate && setSelectedDate(dayjs());
    }
  };

  const handleTimeChange = () => {
    let value = timeString;
    const parsedTime = dayjs(
      value,
      ['hh:mm A', 'hh:mm a', 'h:mm A', 'h:mm a'],
      true,
    );
    if (parsedTime.isValid()) {
      checkDateExists();
      setSelectedTimeString &&
        setSelectedTimeString(parsedTime.format('hh:mm A'));
    } else {
      value = convertOClockOrMillitaryFormat(value);
      if (value !== '') {
        checkDateExists();
      }
      setSelectedTimeString && setSelectedTimeString(value);
    }
  };

  // Toggle time field visibility
  const toggleTimeField = () => {
    setIsTimeVisible(!isTimeVisible);
    setOpenTimeDropDown(false);
  };

  // Handle time selection from dropdown
  const handleTimeSelect = (e: any) => {
    setSelectedTimeString && setSelectedTimeString(e.target.value);
  };

  const handleTimeOptionSelect = (str: string) => {
    setSelectedTimeString && setSelectedTimeString(str);
  };

  const resetState = () => {
    setOpenTimeDropDown(false);
  };

  useEffect(() => {
    eventEmitter.on('resetDateTimeInput', resetState);

    return () => {
      eventEmitter.off('resetDateTimeInput', resetState);
    };
  }, []);

  return (
    <>
      <div className="dateTimeShowcaseHeadingWrap">
        {/* Label for Date */}
        <span>Date</span>
        {/* Clock Icon with Clickable Toggle in Top Right */}
        <span onClick={toggleTimeField}>
          {/* localize tooltip */}
          <Tooltip
            title={
              customfieldCmsData?.lbl_column_date_time_settings
                ?.lbl_date_clock_icon_tooltip_text
            }
          >
            <ClockCircleOutlined />
          </Tooltip>
        </span>
      </div>
      <div className="dateTimeInputFieldWrap">
        {/* Date Input */}
        <Input
          type="text"
          value={dateString}
          onChange={(e) => {
            setSelectedDateString && setSelectedDateString(e.target.value);
          }}
          onBlur={handleDateChange}
          placeholder="Date"
        />

        {/* Time Input Toggle */}
        {isTimeVisible && (
          <>
            <Input
              type="text"
              value={timeString}
              onChange={(e) => handleTimeSelect(e)}
              onBlur={() => {
                handleTimeChange();
              }}
              onClick={() => setOpenTimeDropDown((prevState) => !prevState)}
              placeholder="Time"
            />

            {/* Time Dropdown */}
            {openTimeDropdown && (
              <ul className="dateTimeCFTimeList">
                {timesList.map((time) => (
                  <li
                    key={time}
                    onClick={() => {
                      checkDateExists();
                      handleTimeOptionSelect(time);
                      setOpenTimeDropDown(false);
                    }}
                    className={timeString === time ? 'activeTime' : ''}
                  >
                    {time}
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DateTimeInputWithToggle;
